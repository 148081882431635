<template>
  <Card
    class="text-center"
    title="social_shipping.introduction_title">
    <p v-t="'social_shipping.introduction_description'" />
  </Card>
</template>

<script>
import Card from '@/components/Card';

export default {
  name: 'SocialShippingIntroduction',
  components: { Card },
};
</script>
